/* eslint-disable no-debugger */
/* eslint-disable prettier/prettier */
/*eslint no-warning-comments: "error"*/

import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
import VueCookies from "vue-cookies";

Vue.use(Vuex);
Vue.use(VueCookies);

export default new Vuex.Store({
  state: {
    bannerMessage: "",
    bannerType: "Success",
    customDialog: {
      title: "",
      body: "",
      visible: false,
    },
    noGroupsInfo: false,
    loading: false,
    accessToken: null,
    user: null,
    users: null,
    groups: null,
    channels: null,
    notifications: null,
    permissions: null,
  },
  getters: {
    getAccessToken: state => {
      return state.accessToken;
    },
    getBasicAuth: state => {
      return {
        headers: {
          Authorization: "Bearer " + state.accessToken,
        },
      };
    },
    getBannerMessage: state => {
      return state.bannerMessage;
    },
    getBannerType: state => {
      return state.bannerType;
    },
    getCustomDialog: state => {
      return state.customDialog;
    },
    getNoGroupsInfo: state => {
      return state.noGroupsInfo;
    },
    getLoading: state => {
      return state.loading;
    },
    getAccessMode: state => {
      return state.accessMode;
    },
    getUser: state => {
      return state.user;
    },
    getUsers: state => {
      return state.users;
    },
    getGroups: state => {
      return state.groups;
    },
    getChannels: state => {
      return state.channels;
    },
    getChannelsGroups: state => {
      let v = [];
      state.channels.forEach(e => {
        if (e.group && (v.indexOf(e.group) == -1))
          v.push(e.group);
      });

      return v;
    },
    getNotifications: state => {
      return state.notifications;
    },
    getPermissions: state => {
      return state.permissions;
    },
  },

  mutations: {
    showBanner: (state, payload) => {
      state.bannerMessage = payload.bannerMessage;
      state.bannerType = payload.bannerType;
    },
    showCustomDialog: (state, dialog) => {
      state.customDialog = { visible: true, title: dialog.title, body: dialog.body };
    },
    setNoGroupsInfo: (state, payload) => {
      state.noGroupsInfo = payload;
    },
    setLoading: (state, payload) => {
      state.loading = payload;
    },
    setAccessMode: (state, payload) => {
      state.accessMode = payload;
    },
    setLogin: (state, payload) => {
      Vue.$cookies.set("a", payload);
      state.accessToken = payload;
    },
    setUser: (state, payload) => {
      state.user = payload;
    },
    setLogout: state => {
      Vue.$cookies.remove("a");
      state.accessToken = null;
      state.user = null;
    },
    setUsers: (state, payload) => {

      payload.forEach(item => {
        if (!item.groups)
          item.groups = [];
      });

      state.users = payload;
    },
    setGroups: (state, payload) => {
      state.groups = payload;
    },
    setChannels: (state, payload) => {
      state.channels = payload;
    },
    setNotifications: (state, payload) => {
      state.notifications = payload;
    },
    setPermissions: (state, payload) => {
      state.permissions = payload;
    },
  },

  actions: {
    logout: context => {
      context.commit("setLogout", null);
    },

    registerDevice: (context, payload) => {
      axios
        .post(
          process.env.VUE_APP_API_URL + "/device", { deviceId: payload, notificationToken: payload },
          context.getters.getBasicAuth
        )
        .then(() => {
          console.log("Device registered");
        });
    },

    loadProfile: context => {
      return new Promise((resolve, reject) => {
        context.commit("setLoading", true);

        axios
          .get(process.env.VUE_APP_MP_API_URL + "/authorize", context.getters.getBasicAuth)
          .then(response => {
            context.commit("setUser", response.data.data);

            if (context.getters.getUser.isAdmin) context.commit("setAccessMode", "admin");
            else context.commit("setAccessMode", "operator");
            resolve("/notificaciones");
            context.commit("setLoading", false);
          })
          .catch(error => {
            context.commit("setLogout", null);
            reject(error.message);
          })
          .finally(() => {
            context.commit("setLoading", false);
          });
      });
    },

    fetchUser: context => {
      return new Promise((resolve, reject) => {
        context.commit("setLoading", true);

        axios
          .get(process.env.VUE_APP_MP_API_URL + "/users/me", context.getters.getBasicAuth)
          .then(response => {
            context.commit("setUser", response.data.data);
            resolve(response);
          })
          .catch(error => {
            console.log(error.message);
            reject(error.message);
          })
          .finally(() => {
            context.commit("setLoading", false);
          });
      });
    },

    /* Obtener los usuarios */
    fetchUsers: context => {
      return new Promise((resolve, reject) => {
        context.commit("setLoading", true);
        axios
          .get(process.env.VUE_APP_MP_API_URL + "/users", context.getters.getBasicAuth)
          .then(response => {
            context.commit("setUsers", response.data.data.users);
            resolve(response);
          })
          .catch(error => {
            console.log(error.message);
            reject(error.message);
          })
          .finally(() => {
            context.commit("setLoading", false);
          });
      });
    },

    /* Obtener los canales */
    fetchChannels: context => {
      return new Promise((resolve, reject) => {
        context.commit("setLoading", true);

        axios
          .get(process.env.VUE_APP_API_URL + "/channels", context.getters.getBasicAuth)
          .then(response => {
            context.commit("setChannels", response.data);
            resolve(response);
          })
          .catch(error => {
            console.log(error.message);
            reject(error.message);
          })
          .finally(() => {
            context.commit("setLoading", false);
          });
      });
    },

    createChannel: (context, payload) => {
      return new Promise((resolve, reject) => {

        context.commit("setLoading", true);

        axios
          .post(
            process.env.VUE_APP_API_URL + "/channels", { name: payload.channelName, group: payload.group, description: payload.description },
            context.getters.getBasicAuth
          )
          .then(response => {
            console.log("Channel created " + JSON.stringify(response.data));
            resolve();
          })
          .catch(error => {
            reject(error.message);
          })
          .finally(() => {
            context.commit("setLoading", false);
          });
      });
    },

    updateChannel: (context, payload) => {

      return new Promise((resolve, reject) => {
        context.commit("setLoading", true);

        axios
          .put(
            process.env.VUE_APP_API_URL + "/channels", { id: payload.id, name: payload.channelName, group: payload.group, description: payload.description },
            context.getters.getBasicAuth
          )
          .then(() => {
            console.log("Channel created");
            resolve();
          })
          .catch(error => {
            reject(error.message);
          })
          .finally(() => {
            context.commit("setLoading", false);
          });
      });
    },

    /* Obtiene los canales del usuario*/
    fetchUserChannels: context => {
      return new Promise((resolve, reject) => {
        context.commit("setLoading", true);

        axios
          .get(process.env.VUE_APP_API_URL + "/channel/user", context.getters.getBasicAuth)
          .then(response => {
            resolve(response.data);
          })
          .catch(error => {
            console.log(error.message);
            reject(error.message);
          })
          .finally(() => {
            context.commit("setLoading", false);
          });
      });
    },
    subscribeChannel: (context, payload) => {
      axios
        .post(
          process.env.VUE_APP_API_URL + "/channel/user", { channelId: payload },
          context.getters.getBasicAuth
        )
        .then(() => {
          //console.log("Channel subscribed: " + payload);
        });
    },
    unsubscribeChannel: (context, payload) => {
      axios
        .delete(process.env.VUE_APP_API_URL + "/channel/user", {
          headers: {
            Authorization: "Bearer " + context.getters.getAccessToken,
          },
          data: { channelId: payload },
        })
        .then(() => {
          //console.log("Channel unsubscribed: " + payload);
        });
    },

    saveUser: (context, payload) => {
      return new Promise((resolve, reject) => {
        context.commit("setLoading", true);

        axios
          .put(process.env.VUE_APP_MP_API_URL + "/users", {
            username: payload.username,
            password: payload.password,
            type: payload.type,
          }, context.getters.getBasicAuth)
          .then(() => {
            resolve();
          })
          .catch(() => {
            reject();
          })
          .finally(() => {
            context.commit("setLoading", false);
          });
      });
    },

    changePassword: (context, payload) => {
      axios
        .post(
          process.env.VUE_APP_MP_API_URL + "/users/me/changepass", payload,
          context.getters.getBasicAuth
        )
        .then(() => {
          console.log("Password changed");
        });
    },

    deleteUser: (context, payload) => {
      return new Promise((resolve, reject) => {
        context.commit("setLoading", true);

        axios
          .delete(process.env.VUE_APP_MP_API_URL + "/users/" + payload, {
            headers: {
              Authorization: "Bearer " + context.getters.getAccessToken,
            },
            data: { username: payload.username },
          })
          .then(() => {
            resolve();
          })
          .catch(() => {
            reject();
          })
          .finally(() => {
            context.commit("setLoading", false);
          });
      });
    },

    /* Obtener las notificationes del usuario */
    fetchNotifications: (context, payload) => {
      return new Promise((resolve, reject) => {
        context.commit("setLoading", true);

        let page = payload.page;
        let pageSize = 15;
        let own = payload.own;
        let channels = payload.channels ? payload.channels : "";

        axios
          .get(
            process.env.VUE_APP_API_URL + "/notifications?own=" + own + "&channels=" + channels + "&page=" + page + "&pageSize=" + pageSize,
            context.getters.getBasicAuth
          )
          .then(response => {
            //context.commit("setNotifications", response.data);
            resolve(response.data);
          })
          .catch(error => {
            console.log(error.message);
            reject(error.message);
          })
          .finally(() => {
            context.commit("setLoading", false);
          });
      });
    },

    getUserPermissions: (context, payload) => {
      return new Promise((resolve, reject) => {
        context.commit("setLoading", true);

        axios
          .get(process.env.VUE_APP_MP_API_URL + "/users/" + payload.username + "/channels", context.getters.getBasicAuth)
          .then(response => {
            resolve(response.data.data);
          })
          .catch(error => {
            console.log(error.message);
            reject(error.message);
          })
          .finally(() => {
            context.commit("setLoading", false);
          });
      });
    },

    setUserPermissions: (context, payload) => {
      return new Promise((resolve) => {
        axios
          .post(
            process.env.VUE_APP_MP_API_URL + "/users/" + payload.username + "/" + payload.permission, payload.object,
            context.getters.getBasicAuth
          )
          .then(() => {
            resolve();
          });
      });
    },

    deleteOwnNotifications: (context) => {
      return new Promise((resolve, reject) => {
        axios
          .delete(
            process.env.VUE_APP_API_URL + "/notifications", {
              headers: {
                Authorization: "Bearer " + context.getters.getAccessToken,
              }
            })
          .then(response => {
            resolve(response);
          })
          .catch(error => {
            console.log(error.message);
            reject(error.message);
          });
      });
    },

    deleteNotifications: (context, payload) => {

      axios
        .delete(
          process.env.VUE_APP_API_URL + "/notifications/all", {
            headers: {
              Authorization: "Bearer " + context.getters.getAccessToken,
            },
            data: payload
          })
        .then(response => {
          context.commit("showBanner", {
            bannerMessage: `Se han borrado ${response.data.deletedCount} notificaciones.`,
            bannerType: "success",
          });
        })
        .catch(error => {
          console.log(error.message);

        });

    },

    /* Obtener los grupos */
    fetchGroups: context => {
      return new Promise((resolve, reject) => {
        context.commit("setLoading", true);

        axios
          .get(process.env.VUE_APP_API_URL + "/groups", context.getters.getBasicAuth)
          .then(response => {
            context.commit("setGroups", response.data);
            resolve(response);
          })
          .catch(error => {
            console.log(error.message);
            reject(error.message);
          })
          .finally(() => {
            context.commit("setLoading", false);
          });
      });
    },

    createGroup: (context, payload) => {

      return new Promise((resolve, reject) => {
        context.commit("setLoading", true);

        axios
          .post(
            process.env.VUE_APP_API_URL + "/groups", payload,
            context.getters.getBasicAuth
          )
          .then(() => {
            console.log("Group updated");
            resolve();
          })
          .catch(error => {
            reject(error.message);
          })
          .finally(() => {
            context.commit("setLoading", false);
          });
      });
    },

    updateGroup: (context, payload) => {

      return new Promise((resolve, reject) => {
        context.commit("setLoading", true);

        axios
          .put(
            process.env.VUE_APP_API_URL + "/groups", payload,
            context.getters.getBasicAuth
          )
          .then(() => {
            console.log("Group updated");
            resolve();
          })
          .catch(error => {
            reject(error.message);
          })
          .finally(() => {
            context.commit("setLoading", false);
          });
      });
    },

    decrypt: (context, payload) => {
      return new Promise((resolve, reject) => {
        axios
          .post(process.env.VUE_APP_API_URL + "/decrypt", payload)
          .then(response => {
            resolve(response);
          })
          .catch(error => {
            console.log(error.message);
            reject(error.message);
          });
      });
    },

    loginDirectUser: (context, payload) => {

      return new Promise((resolve, reject) => {
        context
          .dispatch("saveUser", {
            username: payload.username,
            decodedpass: payload.password
          })
          .then(
            () => {
              context
                .dispatch("login", {
                  username: payload.username,
                  password: payload.password,
                  remeberMe: false,
                })
                .then(
                  response => {
                    resolve(response);
                  },
                  error => {
                    reject(error.message);
                  }
                );
            },
            error => {
              reject(error.message);
            }
          );
      });
    },
    testNotification: context => {
      var notif = {
        username: context.getters.getUser.username,
        alert: {
          title: "Notificación silenciosa",
          body: "Este es un ejemplo de una notificación silenciosa",
        },
      };

      axios.post(process.env.VUE_APP_API_URL + "/notifications", notif).then(() => {
        console.log("Notification sended");
      });
    },
  },
  modules: {},
});