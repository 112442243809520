import firebase from "firebase/app";
import "firebase/firebase-messaging";

var firebaseConfig = {
  apiKey: "AIzaSyBMGNUsq4EjelFJQAshutBf3BMe_ZwI-_8",
  authDomain: "emt-alert.firebaseapp.com",
  projectId: "emt-alert",
  storageBucket: "emt-alert.appspot.com",
  messagingSenderId: "61227109961",
  appId: "1:61227109961:web:c911bf0afa1687be08f998",
  measurementId: "G-TFN87SM3ER",
};

firebase.initializeApp(firebaseConfig);

export default firebase.messaging();
