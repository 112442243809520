/* eslint-disable prettier/prettier */
/*eslint no-warning-comments: "error"*/

import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [{
    path: "/",
    name: "Home",
    component: () =>
      import ("../views/Notifications.vue"),
  },
  {
    path: "/notificaciones",
    name: "Notifications",
    component: () =>
      import ("../views/Notifications.vue"),
  },
  {
    path: "/configuracion",
    name: "Configuration",
    component: () =>
      import ("../views/Configuration.vue"),
  },
  {
    path: "/grupos",
    name: "Groups",
    component: () =>
      import ("../views/Groups.vue"),
  },
  {
    path: "/canales",
    name: "Channels",
    component: () =>
      import ("../views/Channels.vue"),
  },
  {
    path: "/usuarios",
    name: "Users",
    component: () =>
      import ("../views/Users.vue"),
  },
  {
    path: "/manage",
    name: "Manage",
    component: () =>
      import ("../views/Manage.vue"),
  },
  {
    path: "/api",
    name: "Api",
    component: () =>
      import ("../views/Api.vue"),
  }
];

const router = new VueRouter({
  mode: "history",
  routes,
});

export default router;