<template>
  <div>
    <v-navigation-drawer v-model="drawer" app disable-resize-watcher>
      <v-list>
        <v-list-item to="/notificaciones">
          Notificaciones
        </v-list-item>
        <v-list-item to="/configuracion">
          Configuración
        </v-list-item>
        <v-list-item v-show="user && user.isAdmin" to="/grupos">
          Grupos
        </v-list-item>
        <v-list-item v-show="user && user.isAdmin" to="/usuarios">
          Usuarios
        </v-list-item>
        <v-list-item v-show="user && user.isAdmin" to="/canales">
          Canales
        </v-list-item>
        <v-list-item v-show="user && user.isAdmin" to="/manage">
          Mantenimiento
        </v-list-item>
        <v-list-item v-show="user && user.isAdmin" to="/api">
          API
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar app flat v-show="user != null" color="appBarColor">
      <v-app-bar-nav-icon color="white" class="hidden-md-and-up" @click="drawer = !drawer" />
      <v-container>
        <v-row>
          <v-img :src="require('@/assets/logo_menu.png')" class="" height="40" max-width="70" />

          <ul class="nav-list d-none d-md-block">
            <li>
              <router-link to="/notificaciones">Notificaciones</router-link>
            </li>
            <li>
              <router-link to="/configuracion">Configuración</router-link>
            </li>
            <li v-show="user && user.isAdmin">
              <router-link to="/grupos">Grupos</router-link>
            </li>
            <li v-show="user && user.isAdmin">
              <router-link to="/usuarios">Usuarios</router-link>
            </li>
            <li v-show="user && user.isAdmin">
              <router-link to="/canales">Canales</router-link>
            </li>        
            <li v-show="user && user.isAdmin">
              <router-link to="/manage">Mantenimiento</router-link>
            </li>
            <li v-show="user && user.isAdmin">
              <router-link to="/api">API</router-link>
            </li>
          </ul>
          <ul class="nav-list-secondary" style="margin-right:-20px">
            <li>
              <a @click="doLogout"
                ><i class="material-icons" title="Cerrar sesión">power_settings_new</i>
              </a>
            </li>
          </ul>
        </v-row>
      </v-container>
    </v-app-bar>
  </div>
</template>

<script>
export default {
  name: "Menu",
  data: () => ({
    drawer: false,
  }),
  computed: {
    user() {
      return this.$store.getters.getUser;
    },
  },
  methods: {
    doLogout() {
      this.$store.dispatch("logout");
      window.location.href = process.env.VUE_APP_MP_LOGIN_URL;
    },
  },
};
</script>

<style scoped>
.appBarColor {
  border-bottom: 1px solid #0033a0;
  background-color: #0072ce;
  background-image: linear-gradient(180deg, #0072ce, #0033a0);
}

.nav-list {
  vertical-align: middle;
}

.nav-list-secondary {
  position: absolute;
  right: 30px;
  vertical-align: middle;
}

#header-logo-emt {
  background: url("../assets/logo_menu.png") no-repeat;
  content: "";
  width: 122px;
  height: 40px;
  display: table-cell;
  background-position: center;
}

ul li {
  display: table-cell;
  cursor: pointer;
  padding: 8px 10px;
  vertical-align: middle;
}

ul li a {
  padding: 10px 10px;
  text-align: justify;
  letter-spacing: 1.5px;
  font-size: 14px;
  font-style: normal;
  text-decoration: none;
  color: #fff;
  -webkit-font-smoothing: antialiased;
}

.nav-list li:hover {
  background-color: #fff;
  border-radius: 5px;
}

.nav-list li:hover a {
  color: #0072ce !important;
}

.nav-list-secondary li a {
  text-decoration: none;
  position: relative;
  padding-left: 10px;
}

.nav-list-secondary li a:hover {
  text-decoration: underline;
}

.material-icons {
  position: absolute;
  top: 5px;
  left: -16px;
}
</style>
