import Vue from "vue";
import Vuetify from "vuetify/lib";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: "#0072ce",
        secondary: "#428139",
        accent: "#0033a0",
        error: "#b71c1c",
        appBackground: "#f5f5f5",
        appBarColor: "#0072ce",
      },
    },
  },
});