<template>
  <v-app>
    <Menu :rol="1" />
    <v-main class="appBackground">
      <Banner></Banner>
      <v-scroll-x-transition mode="out-in" hide-on-leave>
        <router-view />
      </v-scroll-x-transition>
    </v-main>
  </v-app>
</template>

<script>

/* eslint-disable no-debugger */
/* eslint-disable prettier/prettier */
/*eslint no-warning-comments: "error"*/

import Vue from "vue";
import VueCookies from "vue-cookies";

Vue.use(VueCookies);

import Menu from "./components/Menu.vue";
import Banner from "./components/Banner.vue";

export default {
  name: "App",
  components: {
    Menu,
    Banner,
  },
  computed: {
    accessToken() {
      return this.$store.getters.getAccessToken;
    },
  },
  created() {

    // Registering Service Worker
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.register('../sw.js');
    }


    let uri = window.location.search.substring(1);
    let params = new URLSearchParams(uri);
    //var crypted_user = params.get("user");

    //Login automatico de usuario invitado
    /*if (crypted_user != null) {
      this.$store
        .dispatch("decrypt", { text: crypted_user })
        .then(res => {
          if (res.data.code == "00") {
            this.$store
              .dispatch("loginDirectUser", {
                username: res.data.data,
                password: crypted_user,
                remeberMe: false,
              })
              .then(
                response => {
                  this.$router.push(response);
                },
                error => {
                  this.summaryText = error;
                }
              );
          }
        })
        .catch();
    }*/

    var _auth = params.get("auth");
    var a = Vue.$cookies.get("a");

    if (a || _auth) {
      this.$store.commit("setLogin", _auth ?? a);
      this.$store.dispatch("loadProfile").then(response => {
        this.$router.push(response);
      })
      .catch(() => {
        window.location.href = process.env.VUE_APP_MP_LOGIN_URL;
      });
    } else {
      window.location.href = process.env.VUE_APP_MP_LOGIN_URL;
    }
  },
};
</script>

<style>
body {
  margin: 0px;
  height: 100%;
}

.router-link-exact-active {
  color: #0072ce !important;
  background-color: #fff;
  border-radius: 5px;
}

#app {
  font-family: "Roboto", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /*text-align: center;*/
  color: #2c3e50;
}

h3 {
  margin: 5px;
}

pre{
  width: 100%; white-space: break-spaces;
}
</style>
